import type { RouteComponentProps } from "@reach/router";
import React from "react";

import { SessionLoader } from "../session";
import { AccountVerifiedSection } from "../sign-up";
import { assertDefined } from "../utils";

function AccountVerified({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="authenticated" location={location}>
      <AccountVerifiedSection />
    </SessionLoader>
  );
}

export default AccountVerified;
