import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { useAddAlert } from "../alert";
import { analyticsEnqueueEvent } from "../analytics";
import SwapBadgeImage from "../assets/images/Swapbadge.png";
import AuthenticationLayout from "../components/Layout/AuthenticationLayout";
import {
  isSessionVerifyEmailCodeError,
  sessionVerifyEmailCode,
} from "../session";
import { useAppDispatch } from "../store";
import type { UserDetailsType } from "../types";
import type { SignUpStep } from "../utils/enum";
import { handleError } from "../utils/handleError";
import { AccountVerifiedStep } from "./AccountVerifiedStep";

export interface CongratulationDataType {
  details?: UserDetailsType;
  step: SignUpStep;
}

const AccountVerifiedSectionStyles = styled.div``;

export const AccountVerifiedSection = () => {
  const [isDisabledContinueBtn, setStateContinueBtn] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const addAlert = useAddAlert();

  const handleVerifyEmail = useCallback(
    async (oobCode: string) => {
      try {
        await dispatch(sessionVerifyEmailCode({ oobCode })).unwrap();

        dispatch(
          analyticsEnqueueEvent({
            eventName: "emailVerified",
          }),
        );
      } catch (error) {
        if (
          isSessionVerifyEmailCodeError(error) &&
          error.kind === "custom" &&
          error.code === "wrong-account"
        ) {
          message.error(
            "You have confirmed the verification email but it is not for this account, please confirm the correct email!",
          );
          setStateContinueBtn(true);
          return;
        }

        handleError(addAlert, error);
      }
    },
    [addAlert, dispatch],
  );

  useEffect(() => {
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const oobCode = searchParams.get("oobCode");
    if (oobCode) {
      try {
        handleVerifyEmail(oobCode);
      } catch (error) {
        handleError(addAlert, error);
      }
    }
  }, [addAlert, handleVerifyEmail, location.search]);

  return (
    <AccountVerifiedSectionStyles>
      <AuthenticationLayout
        title="Congratulations, you are setup to shop smart and healthy!"
        isTutorial={false}
        srcImage={SwapBadgeImage}
      >
        <AccountVerifiedStep isDisabledContinueBtn={isDisabledContinueBtn} />
      </AuthenticationLayout>
    </AccountVerifiedSectionStyles>
  );
};
