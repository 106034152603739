import { Link } from "gatsby";

import facebookIcon from "../../assets/icons/facebook.svg";
import telegramIcon from "../../assets/icons/telegram.svg";
import footerLogoImage from "../../assets/images/SIconFooter.png";
import twitterIcon from "../../assets/images/twitter-icon.png";
import { getSessionRoute } from "../../session";

export type LayoutFooterProps = {
  /**
   * If provided, the footer will render a sign out button, otherwise it will
   * render a sign in link.
   */
  readonly onSignOutClick: (() => void) | null;
};

const footerLinkClassName =
  "text-white/70 hover:text-white outline-none ring-white focus:ring-2";

const footerSocialLinks: readonly {
  readonly href: string;
  readonly icon: string;
  readonly label: string;
}[] = [
  // {
  //   href: "https://www.facebook.com/",
  //   icon: facebookIcon,
  //   label: "Facebook",
  // },
  // {
  //   href: "https://www.twitter.com/",
  //   icon: twitterIcon,
  //   label: "Twitter",
  // },
  // {
  //   href: "https://www.telegram.org/",
  //   icon: telegramIcon,
  //   label: "Telegram",
  // },
];

const footerLinkGroups: readonly {
  readonly label: string;
  readonly links: readonly ({
    readonly audience: "authenticated" | "public" | "unauthenticated";
    readonly label: string;
  } & (
    | {
        readonly kind: "app";
        readonly to: string;
      }
    | {
        readonly action: "signOut";
        readonly kind: "button";
      }
    | {
        readonly href: string;
        readonly kind: "external";
      }
  ))[];
}[] = [
  {
    label: "Navigation",
    links: [
      {
        audience: "public",
        kind: "app",
        label: "Home",
        to: getSessionRoute("profile"),
      },
      {
        audience: "public",
        href: "https://www.getswapapp.com/",
        kind: "external",
        label: "About Us",
      },
      {
        audience: "authenticated",
        kind: "app",
        label: "Profile",
        to: getSessionRoute("profile"),
      },
      {
        audience: "public",
        href: "https://www.getswapapp.com/terms-of-use",
        kind: "external",
        label: "Terms of Service",
      },
      {
        audience: "public",
        href: "https://www.getswapapp.com/privacy-policy",
        kind: "external",
        label: "Privacy Policy",
      },
      {
        audience: "authenticated",
        action: "signOut",
        kind: "button",
        label: "Sign Out",
      },
      {
        audience: "unauthenticated",
        kind: "app",
        label: "Sign In",
        to: getSessionRoute("signIn"),
      },
    ],
  },
  {
    label: "Contact Us",
    links: [
      {
        audience: "public",
        href: "mailto:support@swaphealth.com",
        kind: "external",
        label: "support@swaphealth.com",
      },
    ],
  },
];

const year = new Date().getFullYear();

export function LayoutFooter({ onSignOutClick }: LayoutFooterProps) {
  const isAuthenticated = !!onSignOutClick;

  return (
    <footer className="md:col-span-2 px-4 md:px-8 lg:px-14 xl:px-[4.5rem] py-14 text-white bg-ral-9005">
      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-8 mx-auto max-w-7xl">
        <section>
          <figure className="flex items-center mb-6">
            <img
              alt="Swap logo"
              className="mr-2 lg:mr-4 w-[32px] h-[32px] lg:w-[72px] lg:h-[72px]"
              src={footerLogoImage}
            />
            <figcaption className="text-base lg:text-5xl font-bold font-montserrat italic">
              Swap
            </figcaption>
          </figure>

          <p className="mb-8 text-sm lg:text-2xl text-white/70 font-extralight italic">
            Healthy Choices Rewarded
          </p>

          {/* <nav className="mb-16 lg:mb-24">
            <p className="sr-only">Social Links</p>

            <ul className="flex">
              {footerSocialLinks.map(({ href, icon, label }) => (
                <li
                  className="mr-2 last:mr-0 focus-within:ring-2 ring-white"
                  key={href}
                >
                  <a
                    className="block w-8 h-8 lg:w-12 lg:h-12 outline-none brightness-75 hover:brightness-100"
                    href={href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt={label} className="w-full h-full" src={icon} />
                  </a>
                </li>
              ))}
            </ul>
          </nav> */}

          <p className="text-white/70 font-extralight" suppressHydrationWarning>
            Copyright {year} Swap - All Rights Reserved
          </p>
        </section>

        {footerLinkGroups.map(({ label, links }) => (
          <section key={label}>
            <h1 className="mb-2 text-white uppercase">{label}</h1>

            <nav>
              <ul>
                {links.map(
                  (link) =>
                    (link.audience === "public" ||
                      (link.audience === "authenticated" && isAuthenticated) ||
                      (link.audience === "unauthenticated" &&
                        !isAuthenticated)) && (
                      <li className="mb-2" key={link.label}>
                        {link.kind === "app" ? (
                          <Link className={footerLinkClassName} to={link.to}>
                            {link.label}
                          </Link>
                        ) : link.kind === "button" ? (
                          <button
                            className={footerLinkClassName}
                            type="button"
                            onClick={onSignOutClick || undefined}
                          >
                            {link.label}
                          </button>
                        ) : (
                          <a
                            className={footerLinkClassName}
                            href={link.href}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {link.label}
                          </a>
                        )}
                      </li>
                    ),
                )}
              </ul>
            </nav>
          </section>
        ))}
      </div>
    </footer>
  );
}
