import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { getAuth } from "firebase/auth";
import { navigate } from "gatsby";
import React from "react";

import confettiGif from "../assets/images/confetti.gif";
import EmailSign from "../assets/images/EmailSign.png";
import AppButton from "../components/BaseComponents/Button";

const AccountVerifiedStepStyles = styled.div``;

type AccountVerifiedStepProps = {
  readonly isDisabledContinueBtn: boolean;
};

export function AccountVerifiedStep({
  isDisabledContinueBtn,
}: AccountVerifiedStepProps) {
  // TODO: Move this to a Redux call.
  const onContinue = () => {
    const user = getAuth().currentUser;
    if (user) {
      user.reload();
      navigate("/tutorial");
    } else {
      navigate("/login");
    }
  };

  return (
    <AccountVerifiedStepStyles className="grid justify-items-center relative min-h-screen xs:min-h-655 ">
      <img
        src={confettiGif}
        alt=""
        className="w-full h-auto"
        css={css`
          margin-top: -320px;
        `}
      />
      <div
        className="absolute grid grid-rows-r2-max-content-auto justify-items-center h-screen-277 max-w-625 w-full xs:h-full-121"
        css={css`
          top: 277px;

          @media (max-width: 550px) {
            top: 121px;
          }
        `}
      >
        <img
          src={EmailSign}
          alt=""
          css={css`
            margin-bottom: 19px;
          `}
        />
        <h3
          className="text-snap-black font-700"
          css={css`
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 18px;
          `}
        >
          Account Verified
        </h3>
        <AppButton
          className="max-w-330 w-full justify-self-center self-end"
          css={css`
            margin-bottom: 33px;

            @media (max-width: 768px) {
              margin: 0;
            }
          `}
          onClick={() => onContinue()}
          disabled={isDisabledContinueBtn}
        >
          GET STARTED
        </AppButton>
      </div>
    </AccountVerifiedStepStyles>
  );
}
